
  import { defineComponent } from 'vue';
  import pageTitle from '@/components/pageTitle.vue';
  import { getPersonList,getStoreList,getRolesList,addPerson,getPersonDetail,editPerson,delPerson } from '@/axios/brand';
  import { ElMessage,ElMessageBox,FormInstance } from 'element-plus';

  export default defineComponent({
    name: 'staffManager',
    data(){
      return {
        names: '',
        account: '',
        accountDialog: false,
        tableData: [],
        form: {
          name: '',
          id: 0,
          account: '',
          role_id: '',
          store_ids: [],
          is_login: 1,
          password: ''
        },
        storeList: [{id:'',store_name:''}],
        roleList: [{name:'',id:''}],
        rules: {
          name: [
            { required: true, message: '请输入员工姓名', trigger: 'blur' }
          ],
          account: [
            { required: true, message: '请输入员工账号', trigger: 'blur' }
          ],
          role_id: [
            { required: true, message: '请选择员工角色', trigger: 'blur' }
          ],
          is_login: [
            { required: true, message: '请选择状态', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ],
          store_ids: [
            { type: 'array', required: true, message: '请选择管理门店', trigger: 'change' }
          ]
        }
      }
    },
    components: {
      pageTitle
    },
    mounted(){
      let _this = this;
      this.getPersonLists();
      
      let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
        // --->
      getStoreList({
        brand_pk: aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_pk
      }).then((data:any) => {
        console.log(data)
        _this.storeList = data;
      }).catch((err:any) => {
        console.log(err)
      })
        // --->
      getRolesList({
        brand_pk: aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_pk
      }).then((data:any) => {
        console.log(data)
        _this.roleList = data;
      }).catch((err:any) => {
        console.log(err)
      })
    },
    methods: {
      getPersonLists: function(){
        let _this = this;
        let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
        // --->
        getPersonList({
          brand_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_id,
          account: this.account,
          user_name: this.names
        }).then((data:any) => {
          console.log(data)
          console.log('--------------------->')
          _this.tableData = data;
        }).catch((err:any) => {
          console.log(err)
        })
      },
      showAccount: function(id?:number){
        this.accountDialog = true;
        if(id){
          let _this = this;
          getPersonDetail({
            id: id
          }).then((data:any) => {
            _this.form = data;
            _this.form.password = '';
            _this.form.store_ids = data.company_id.map((item:any) => +item)
          }).catch((err:any) => {

          })
        }
        else{
          this.form.password = 'yc123456';
        }
      },
      //确认
      staffClick: function(){
        let _this = this;
        (this.$refs.accountForm as FormInstance).validate((valid:any, fields:any) =>{
          if (valid) {
            //新增
            if(!this.form.id){
              let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
              addPerson({
                brand_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_id,
                brand_pk: aci.company_list[sessionStorage.getItem('checkCompany') || 0].brand_pk,
                is_login: this.form.is_login,
                account: this.form.account,
                name: this.form.name,
                store_ids: this.form.store_ids.join(','),
                role_id: this.form.role_id,
                password: this.form.password
              }).then((data:any) => {
                ElMessage('新增员工成功')
                _this.getPersonLists();
                _this.closeInfo();
              }).catch((err:any) => {

              })
            }
            else{
              editPerson({
                id: this.form.id,
                is_login: this.form.is_login,
                account: this.form.account,
                name: this.form.name,
                store_ids: this.form.store_ids.join(','),
                role_id: this.form.role_id,
                password: this.form.password
              }).then((data:any) => {
                ElMessage('编辑员工成功')
                _this.getPersonLists();
                _this.closeInfo();
              }).catch((err:any) => {

              })
            }
          }
        })
      },
      delStaff: function(id:number){
        let _this = this;
        ElMessageBox.confirm('是否删除该员工').then(() => {
          delPerson({
            id: id
          }).then((data:any) => {
            ElMessage('删除员工成功')
            _this.getPersonLists();
            _this.closeInfo();
          }).catch((err:any) => {

          })
        }).catch(() => {})
      },
      //关闭弹窗
      closeInfo: function(){
        this.accountDialog = false;
        this.form = {
          name: '',
          id: 0,
          account: '',
          role_id: '',
          store_ids: [],
          is_login: 1,
          password: ''
        }
      }
    }
  });
